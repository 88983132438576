import axios from 'axios';
import DraDataLoader from "./DraDataLoader";
import {useState} from "react";
import {useAuth} from "react-oidc-context";

const PdfDownloader = (props) => {
    const [isPdfLoading, setIsPdfLoading] = useState(false);
    const auth = useAuth();

    const downloadPdf = async () => {
        setIsPdfLoading(true);
        const token = auth.user?.access_token
        try {
            await axios.get(`${process.env.REACT_APP_DRA_BASE_URL}/pdf`, {
                responseType: "blob",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/octet-stream',
                    'Content-Disposition':'attachment; filename=Document Printed.pdf',
                    'Authorization': `Bearer ${token}`,
                }, params:{
                    adviceIdNumber: props.adviceIdNumber
                }
            }).then((response) => {
                setIsPdfLoading(false);
                // Create a Blob from the response data
                const pdfBlob = new Blob([response.data], { type: "application/pdf" });

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(pdfBlob);

                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute(
                    "download",
                    `Document Printed.pdf`
                ); // Set the desired filename for the downloaded file

                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();

                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            }).catch((error) => {
                // log request error if any
                console.error('Error:', error);
                setIsPdfLoading(false);
            });
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
    };
    if(!isPdfLoading) {
        return (
            <button type='submit' className='fmc-button' value={props.adviceIdNumber}
                    onClick={downloadPdf}>PDF</button>
        );
    }else {
        return (
            <div>
                <DraDataLoader />
            </div>
        );
    }
}

export default PdfDownloader;