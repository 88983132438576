import '../styles/dra.css';
import '../styles/fds-optimized.css';
import DraDataLoader from './DraDataLoader';
import PdfDownloader from './PdfDownloader';

function DraResults(props) {
    if (!props.isSearchResultsLoading && props.isSearchClicked) {
        let hideDGColumn = true;
        let hideRegNoCustName = false;
        if (props.dealerGroup !== '') {
            hideDGColumn = false;
        }

        if (props.adviceType === '' || props.adviceType === 'RA') {
            hideRegNoCustName = true;
        }

        return (
            <div id='search_res'>
                <div id='search_results' hidden={!props.isSearchClicked || props.advices.length === 0}>

                    <table id="results" className="fmc-table display responsive">
                        <thead>
                        <td>Date</td>

                        <td hidden={hideDGColumn}>Dealer Number</td>
                        <td hidden={hideDGColumn}>Dealer Name</td>

                        <td hidden={hideRegNoCustName}>Registration Number</td>
                        <td hidden={hideRegNoCustName}>Customer Name</td>
                        <td>Amount Paid(£)</td>
                        <td>PDF</td>
                        </thead>
                        <tbody>

                        {
                            props.advices?.map((advice) => (
                                <tr key={advice.adviceIdNumber}>
                                    <td>{advice.adviceDatedTs}</td>

                                    <td hidden={hideDGColumn}>{advice.dealerIdNumber}</td>
                                    <td hidden={hideDGColumn}>{advice.dealerName}</td>

                                    <td hidden={hideRegNoCustName}>{advice.vehicleRegNumber}</td>
                                    <td hidden={hideRegNoCustName}>{advice.customerName}</td>

                                    <td>{advice.totalAmount}</td>
                                    <td>
                                        <PdfDownloader adviceIdNumber={advice.adviceIdNumber}/>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>

                </div>

                <div id='no_search_results'
                     hidden={props.isSearchResultsFailed || (props.isSearchClicked && props.advices.length !== 0)
                         || (!props.isSearchClicked && props.advices.length !== 0)}>
                    No Records Found...
                </div>
                <div id='failed_search_results' className="error-message"
                     hidden={(props.isSearchClicked && !props.isSearchResultsFailed)}>
                    Search failed! Please review your input data and try again!
                </div>
            </div>

        );
    } else if (props.isSearchResultsLoading) {
        return (
            <div>
                <DraDataLoader/>
            </div>
        );
    }
}

export default DraResults;