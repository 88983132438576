import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "react-oidc-context";

const oidcConfig = {
    authority: process.env.REACT_APP_ADFS_AUTHORITY,
    response_type: 'code',
    scope: 'openid',
    client_id: process.env.REACT_APP_ADFS_CLIENT_ID,
    resource: process.env.REACT_APP_ADFS_RESOURCE,
    redirect_uri: process.env.REACT_APP_UI_HOSTNAME,
    metadata: {
        issuer: process.env.REACT_APP_ADFS_AUTHORITY + '/adfs/services/trust',
        authorization_endpoint: process.env.REACT_APP_ADFS_AUTHORITY + '/adfs/oauth2/authorize',
        token_endpoint: process.env.REACT_APP_ADFS_TOKEN_URL,
        introspection_endpoint: process.env.REACT_APP_ADFS_AUTHORITY + '/oauth/introspect',
        userinfo_endpoint: process.env.REACT_APP_ADFS_AUTHORITY + '/adfs/userinfo',
        jwks_uri: process.env.REACT_APP_ADFS_AUTHORITY + '/adfs/discovery/keys',
        end_session_endpoint: process.env.REACT_APP_UI_HOSTNAME,
    },
};

function onSignInCallback() {
    window.location.href = "/";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig} onSigninCallback={onSignInCallback}>
            <App/>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
