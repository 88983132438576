import { RotatingLines } from "react-loader-spinner";

function DraDataLoader() {
  return (
    <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true} 
    />
  )
}

export default DraDataLoader;

// you need to use this Loader component in the 
// TableComponentWithLoader component