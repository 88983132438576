import '../styles/dra.css';
import '../styles/fds-optimized.css';
import axios from 'axios';
import {useEffect, useState} from "react";
import DraDataLoader from './DraDataLoader'
import DraFooter from './DraFooter';
//import DataTable from 'react-data-table-component';
import DraResults from './DraResults';
import {useAuth} from "react-oidc-context";


function DealerReportingSearch() {
    const auth = useAuth();

    const [isFilterDataLoading, setIsFilterDataLoading] = useState(false);
    const [isLoginError, setIsLoginError] = useState(false);
    const [isSearchResultsLoading, setIsSearchResultsLoading] = useState(false);
    const [isSearchResultsFailed, setIsSearchResultsFailed] = useState(false);
    const [hideRegistrationNoField, setHideRegistrationNoField] = useState(false);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [dealers, setDealers] = useState();
    const [dealerGroups, setDealerGroups] = useState();
    const [userLogin, setUserLogin] = useState();
    const [adviceTypes, setAdviceTypes] = useState();

    const [searchForm, setSearchForm] = useState({
        adviceType: "",
        dealer: "",
        group: "",
        date: "",
        registrationNumber: "",
        amountPaid: ""
    });

    const [advices, setAdvices] = useState([]);

    /* manage side effects */
    useEffect(() => {
        (async () => await load())();
    }, []);

    /* Loading dra search form field data - drop down select data */
    async function load() {
        setIsFilterDataLoading(true);
        setIsLoginError(false);

        const token = auth.user?.access_token
        await axios.get(`${process.env.REACT_APP_DRA_BASE_URL}/info`, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            let response = res.data;
            setDealers(response.dealers);
            setDealerGroups(response.dealerGroups);
            setUserLogin(response.userLogin);
            setAdviceTypes(response.adviceTypes);
            setIsFilterDataLoading(false);
            // pre-select the first advice type
            setAdviceTypeSearchFrom(response.adviceTypes[0]);
        }).catch((error) => {
            // log request error if any
            console.error('Error:', error);
            setIsFilterDataLoading(false);
            // show error message
            setIsLoginError(true);
        });
    }

    const setAdviceTypeSearchFrom = (adviceType) => {
        setSearchForm((prevState) => ({...prevState, ['adviceType']: adviceType.adviceTypeCode}));
        if (adviceType.adviceTypeCode === 'PA') {
            setHideRegistrationNoField(false);
        } else {
            setHideRegistrationNoField(true);
        }
    }

    /* Setting form fields data to the search object */
    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === "amountPaid") {
            if (!isAmountPaidValid(value)){
                return;
            }
        }
        setSearchForm((prevState) => ({...prevState, [name]: value}));
    };

    const isAmountPaidValid = (amountPaid) => {
        // accept only numbers with decimal
        const amountPaidPattern = /^\d*\.?\d*$/;
        return amountPaidPattern.test(amountPaid);
    };

    const handleChangeAdviceType = (event) => {
        const {name, value} = event.target;
        setSearchForm((prevState) => ({...prevState, [name]: value}));
        if (event.target.value === '' || event.target.value === 'PA') {
            setHideRegistrationNoField(false);
        } else {
            setHideRegistrationNoField(true);
        }

        setSearchForm((prevState) => ({
            ...prevState,
            ['group']: '',
            ['dealer']: '',
            ['date']: '',
            ['registrationNumber']: '',
            ['amountPaid']: ''
        }));

        setIsSearchClicked(false);
        setIsSearchResultsFailed(false);
    };

    const handleChangeDealer = (event) => {
        if (event.target.value !== '') {
            setSearchForm((prevState) => ({...prevState, ['group']: ''}));
        }
        const {name, value} = event.target;       
        setSearchForm((prevState) => ({...prevState, [name]: value}));
        setIsSearchClicked(false);
        setIsSearchResultsFailed(false);
    };

    const handleChangeDealerGroup = (event) => {
        if (event.target.value !== '') {
            setSearchForm((prevState) => ({...prevState, ['dealer']: ''}));
        }
        const {name, value} = event.target;
        setSearchForm((prevState) => ({...prevState, [name]: value}));
        setIsSearchClicked(false);
        setIsSearchResultsFailed(false);
    };

    /* Calling search endpoint for the advices */
    const handleSubmit = (event) => {
        event.preventDefault();

        setIsSearchResultsLoading(true);
        setIsSearchResultsFailed(false);
        setIsSearchClicked(true);

        const token = auth.user?.access_token

        const params = {}
        if (searchForm.adviceType) {
            params.adviceType = searchForm.adviceType
        }
        if (searchForm.dealer) {
            params.dealer = searchForm.dealer
        }
        if (searchForm.group) {
            params.group = searchForm.group
        }
        if (searchForm.date) {
            params.date = searchForm.date
        }
        if (searchForm.registrationNumber) {
            params.registrationNumber = searchForm.registrationNumber
        }
        if (searchForm.amountPaid) {
            params.amountPaid = searchForm.amountPaid
        }

        axios.get(`${process.env.REACT_APP_DRA_BASE_URL}/advices`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
            }, params: params
        })
            .then((response) => {
                setAdvices(response.data);
                setIsSearchResultsLoading(false);
                setIsSearchResultsFailed(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsSearchResultsLoading(false);
                setIsSearchResultsFailed(true);
            })
    };

    if (!isFilterDataLoading && !isLoginError) {
        return (

            <div id='dealerRepSch'>
                <div id='header' className='dra-header'>
                    <div className='app-branding'>
                        <img src={require('../assets/images/FordCredit_Blue_Screen_RGB_v1.svg').default}
                             alt='ford-credit-logo' width='331.15' height='90'/>
                    </div>
                    <div>
                        <ul className='nav-global'>
                            <li className='nav-global-welcome'>
                                <span id='loggedInUserWelcome'>Welcome</span>
                                <span id='loggedInUserName'> {userLogin} </span>
                            </li>
                        </ul>
                    </div>
                    <div className='ui-menubar'></div>

                    <div className='welcome'>
                        <h3 className='fmc-type--heading3'>
                            Welcome to Dealer Reporting
                        </h3>
                    </div>
                </div>

                <div id="panel">
                    <div id="search-filters-dra" className="search-filters">
                        <form onSubmit={handleSubmit}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <label>Document Type</label>
                                    </td>
                                    <td>
                                        <select data-testid='adviceTypeSelector'
                                            id='adviceTypeSelector'
                                                className='fmc-select'
                                                name='adviceType'
                                                value={searchForm.adviceType}
                                                onChange={handleChangeAdviceType}>
                                            {
                                                adviceTypes?.map((adviceType) => (
                                                    <option key={adviceType.adviceTypeCode}
                                                            value={adviceType.adviceTypeCode}>{adviceType.adviceTypeDesc}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>Date</label>
                                    </td>
                                    <td>
                                        <input data-testid='date' className="fmc-input" type="date" name='date' value={searchForm.date}
                                               onChange={handleChange}/>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <label>Dealer</label>
                                    </td>
                                    <td>
                                        <select data-testid='dealerSelector' id="dealerSelector" className="fmc-select" name='dealer'
                                                value={searchForm.dealer} onChange={handleChangeDealer}>
                                            <option value="">--select--</option>
                                            {
                                                dealers?.map((dealer) => (
                                                    <option key={dealer.dealerIdNumber}
                                                            value={dealer.dealerIdNumber}>{dealer.dealerIdNumber + '-' + dealer.dealerName}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <label>Group</label>
                                    </td>
                                    <td>
                                        <select data-testid='groupSelector' id="groupSelector" className="fmc-select" name='group'
                                                value={searchForm.group} onChange={handleChangeDealerGroup}>
                                            <option value="">--select--</option>
                                            {
                                                dealerGroups?.map((dealerGroup) => (
                                                    <option key={dealerGroup.dealerGroupIdNumber}
                                                            value={dealerGroup.dealerGroupIdNumber}>{dealerGroup.dealerGroupName?(dealerGroup.dealerGroupIdNumber + '-' + dealerGroup.dealerGroupName):dealerGroup.dealerGroupIdNumber}</option>
                                                ))
                                            }
                                        </select>
                                    </td>
                                </tr>

                                <tr hidden={hideRegistrationNoField}>
                                    <td>
                                        <label>Registration Number</label>
                                    </td>
                                    <td>
                                        <input data-testid='registrationNumber' type="text" className="fmc-input" placeholder="Registration number"
                                               name='registrationNumber' value={searchForm.registrationNumber}
                                               onChange={handleChange} disabled={hideRegistrationNoField}/>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <label>Amount Paid</label>
                                    </td>
                                    <td>
                                        <input data-testid='amountPaid' type="text" className="fmc-input" placeholder="Amount paid"
                                               name='amountPaid' value={searchForm.amountPaid} onChange={handleChange}/>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <button name='submit_button' className='fmc-button' type='submit'>Search
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </form>
                    </div>

                    <div id="search_result_data">

                        {/* <DataTable columns={columns} data={advices} /> */}

                        <DraResults advices={advices} isSearchResultsLoading={isSearchResultsLoading}
                                    isSearchResultsFailed={isSearchResultsFailed}
                                    adviceType={searchForm.adviceType} isSearchClicked={isSearchClicked}
                                    dealerGroup={searchForm.group}/>
                    </div>

                </div>

                <div id='footer-dra'>
                    <DraFooter/>
                </div>

            </div>
        );
    } else if (isFilterDataLoading && !isLoginError) {
        return (
            <div>
                <DraDataLoader/>
            </div>
        );
    } else if (isLoginError) {
        return <div>Full authentication is required to access this resource</div>;
    }
}

export default DealerReportingSearch;